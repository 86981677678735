<template>
  <div class="card">
    <div class="card-body pt-0 pb-0">
      <template v-if="user.admin">
        <TabNavigate :items="[
          {id: 'recent-case', router: `/list-case/recent`, name: 'Recent Cases'},
          {id: 'watching-case', router: `/list-case/watching`, name: 'Watching Cases'},
          {id: 'my-cases', router: `/list-case/my-cases`, name: 'My Cases'},
          {id: 'deleted-cases', router: `/list-case/deleted-cases`, name: 'Deleted Cases'},
        ]"/>
      </template>
      <template v-else>
        <TabNavigate :items="[
          {id: 'recent-case', router: `/list-case/recent`, name: 'Recent Cases'},
          {id: 'watching-case', router: `/list-case/watching`, name: 'Watching Cases'},
          {id: 'my-cases', router: `/list-case/my-cases`, name: 'My Cases'},
          {id: 'my-cases', router: `/list-case/my-cases`, name: 'My Cases'},
        ]"/>
      </template>

    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import TabNavigate from "@/components/base/common/TabNavigate";
import {computed} from "vue";
import store from "@/store";

export default {
  name: "List",
  components: {TabNavigate},
  setup() {
    const user = computed(() => store.getters.currentUser);
    return {
      user
    }
  }
}
</script>

<style scoped>

</style>